export default [
    /** Dev **/
    {
        path: '/dev/tools',
        name: 'dev-tools',
        component: () => import('@/views/dev/tools/Tools.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/tools/selections',
        name: 'dev-tools-selections',
        component: () => import('@/views/dev/tools/selections/Selections.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/tools/invoices',
        name: 'dev-tools-invoices',
        component: () => import('@/views/dev/tools/invoices/Invoices.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/import',
        name: 'dev-import',
        component: () => import('@/views/dev/import/Import.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/export',
        name: 'dev-export',
        component: () => import('@/views/dev/export/Export.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/export-dev',
        name: 'dev-export-dev',
        component: () => import('@/views/dev/export/ExportDev.vue'),
        meta: {
            breadcrumb: [
                {
                    text: 'Dev',
                },
                {
                    text: 'Export',
                    active: true,
                },
            ],
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/cognito',
        name: 'dev-cognito',
        component: () => import('@/views/dev/cognito/Tools.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/cognito/attribute',
        name: 'dev-cognito-attribute',
        component: () => import('@/views/dev/cognito/AddUserIdAttribute.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/selections-all',
        name: 'dev-selections-all',
        props: true,
        component: () => import('@/views/dev/selections/Selections.vue'),
        meta: {
            pageTitle: 'Selection',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Selections',
                    to: { name: 'all-state-selections' }
                },
                {
                    text: 'All',
                    active: true,
                },
            ],
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/acceptance-all',
        name: 'dev-acceptance-all',
        props: true,
        component: () => import('@/views/all-state/acceptance/AcceptanceAll.vue'),
        meta: {
            pageTitle: 'Acceptance',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Acceptance',
                    to: { name: 'all-state-acceptance' }
                },
                {
                    text: 'All',
                    active: true,
                },
            ],
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/dev/table',
        name: 'dev-table',
        component: () => import('@/views/dev/devTable/DevTable.vue'),
        meta: {
            subject: 'dev',
            action: 'read',
            hasSettings: false
        },
    },
]
